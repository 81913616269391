import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

import './_rates-list.scss';

const RatesList = props => {
  return (
    <div className={classnames('rates-list', props.className)}>
      {props.data.map((itemProps, i) => {
        return <RatesListItem {...itemProps} key={`rates-list-${i}`} />;
      })}
    </div>
  );
};

const RatesListItem = props => {
  return (
    <div className="rates-list-item">
      <div className="rates-list-item__rate">
        {props.largeText && (
          <h3 className="rates-list-item__large-text">{props.largeText}</h3>
        )}
        {props.showExtras && (
          <div className="rates-list-item__extras">
            <div className="rates-list-item__percentage">%</div>
            <div className="rates-list-item__pa">p.a.</div>
          </div>
        )}
      </div>
      {props.smallText && (
        <div className="rates-list-item__small-text">
          {ReactHtmlParser(props.smallText)}
        </div>
      )}
    </div>
  );
};

RatesList.propTypes = {
  className: PropTypes.string
};

export default RatesList;
