import * as React from 'react';
import { Link } from '@latitude/link';
import { BUTTON_STYLE } from '@latitude/core/utils/constants';
import BrandedCallout from '@latitude/callout/BrandedCallout';

export const CalloutSection = () => (
    <BrandedCallout
      line1={
        <React.Fragment>
          <span>For FAQs, useful information and quick and easy forms</span>
          <br />
        </React.Fragment>
      }
      cta={
        <React.Fragment>
          <p>
            <Link
              button={BUTTON_STYLE.SECONDARY_INVERSE}
              href="https://latitudefs.zendesk.com/hc/en-au"
              trackId="callout-visit-our-help-centre"
              trackEventData={{ location: "We're here to help" }}
            >
              Visit our Help Centre
            </Link>
          </p>
        </React.Fragment>
      }
    />
  );
