import React from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { AccordionSidebar } from '@latitude/accordion';
import { Heading4 } from '@/components/Heading/Heading';
import { Box } from '@/components/Box/Box';
import { ALIGN, COLOR, BREAKPOINT, MARGIN } from '../../utils/constants';

const heading = 'Frequently asked questions';

export default ({ data }) => (
  <AnalyticsLocationProvider location={heading}>
    <div id="faq">
      {' '}
      <Box.Section
        backgroundColor={COLOR.GREY6}
        css={`
          padding: 8px 0;
          @media (min-width: ${BREAKPOINT.LG}) {
            padding: 40px 0;
          }
          .accordion__item-body .accordion__item-body {
            padding-top: 16px !important;
          }

          .accordion__item-body p {
            margin-top: 0;
          }
        `}
      >
        <Box padding="0 15px">
          <Heading4
            align={ALIGN.CENTER}
            color={COLOR.BLACK}
            marginBottom={MARGIN.M40}
          >
            {heading}
          </Heading4>
          <AccordionSidebar
            data={data.map(group => ({
              groupName: group.title,
              groupData: group.qa.map(item => ({
                title: item.q,
                content: item.a
              }))
            }))}
          />
        </Box>
      </Box.Section>
    </div>
  </AnalyticsLocationProvider>
);
